<div class="row m-auto footer p-4" id="footerContact">
    <!-- Psst, tu souhaites connaitre les fondateurs de Retro Motors ? Essaie avec /qui-sommes-nous ... -->
    <div class="col-md-4 m-auto">
        <img src="assets/footer/logo.webp" alt="Logo Retro Motors" class="img-fluid ball d-block mx-auto" width="300" height="300">
    </div>
    <div class="col-md-4 m-auto">
        <div class="my-3">
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><span><i class="fa-solid fa-phone"></i><p class="d-inline ps-2">Contact</p></span></div>
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><a href="tel:+33787400508"><p class="d-inline">07.87.40.05.08</p></a></div>    
        </div>
        <div class="my-3">
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><span><i class="fa-solid fa-envelope"></i><p class="d-inline ps-2">Email</p></span></div>
        <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><a href="mailto:retromotors.contact&#64;gmail.com">retromotors.contact&#64;gmail.com</a></div>
        </div>
        <div class="my-3">
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><span><i class="fa-solid fa-clock"></i><p class="d-inline ps-2">Horaires</p></span></div>
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center">Lundi au vendredi : 9h - 18h</div>
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center">Samedi : 10h - 16h30</div>
        </div>
    </div>
    <div class="col-md-4 m-auto">
        <div class="my-3">
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center"><span><i class="fa-solid fa-location-dot"></i><p class="d-inline ps-2">Adresse</p></span></div>
            <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center">35 rue de la Sauge - 45430 Chécy</div>
        </div>
        <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center">
            <a style="text-decoration: none" id='Setmore_button_iframe' href='https://booking.setmore.com/scheduleappointment/b2cfe000-c9db-4b81-ad05-334aa223be33'>
                <button class="m-md-2 reservation w-100 px-3">Réserver maintenant !</button>
            </a>
        </div>
        <div class="d-flex flex-row justify-content-center justify-content-md-start align-items-center my-2 py-3">
            <div class="d-col">
                <a href="https://www.facebook.com/profile.php?id=100083735288508" target="_blank" class="px-3">
                    <img src="assets/footer/facebook.png" alt="Page Facebook de Retro Motors" width="64" height="64">
                </a>
            </div>
            <div class="d-col">
                <a href="https://www.instagram.com/retro__motors" target="_blank" class="px-3">
                    <img src="assets/footer/instagram.png" alt="Page Instagram de Retro Motors" width="64" height="64">
                </a>
            </div>
            <div class="d-col">
                <a href="https://www.linkedin.com/company/retro-motors" target="_blank" class="px-3">
                    <img src="assets/footer/linkedin.png" alt="Page LinkedIn de Retro Motors" width="64" height="64">
                </a>
            </div>
        </div>
    </div>
</div>