<div class="bloc">
    <div class="col m-5 px-md-5">
        <div class="row py-4 m-md-4">
            <h1 class="text-center title">Qui sommes-nous ?</h1>
        </div>
        <div class="row py-4 m-md-4">
            <div class="col-md-3 m-auto">
                <img [src]="imageBoss" alt="Le patron" width="300" height="300"
                    class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
            </div>
            <div class="col-md-9 my-auto">
                <h2 class="m-2 py-3 text-center text-md-start">Le Boss</h2>
                <p class="row m-2 py-3 description">Lui, c'est le patron. Addict de la proprété, aucune trace ne lui
                    échappe ! Il a fondé Retro Motors en 2023 dès la fin de ses études. Et depuis, il a sollicité (de
                    force) ses deux compères.</p>
            </div>
        </div>
        <div class="row py-4 m-md-4">
            <div class="col-md-3 order-md-2 m-auto">
                <img [src]="imageDevsecops" alt="L'ingénieur informatique" width="300" height="300"
                    class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
            </div>
            <div class="col-md-9 order-md-1 my-auto">
                <h2 class="m-2 py-3 text-center text-md-start">L'ingénieur informatique</h2>
                <p class="row m-2 py-3 description">Si vous êtes sur ce site aujourd'hui, c'est bien grâce à lui ! Tombé
                    dedans quand il était petit, il développe, optimise et sécurise tout ce qu'il conçoit.</p>
            </div>
        </div>
        <div class="row py-4 m-md-4">
            <div class="col-md-3 m-auto">
                <img [src]="imagePhotographe" alt="Le daguerréotypeur" width="300" height="300"
                    class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
            </div>
            <div class="col-md-9 my-auto">
                <h2 class="m-2 py-3 text-center text-md-start">Le photographe</h2>
                <p class="row m-2 py-3 description">Vous savez, moi je ne crois pas qu’il y ait de bonne ou de mauvaise
                    situation. Moi, si je devais résumer ma vie aujourd’hui avec vous, je dirais que c’est d’abord des
                    rencontres. Des gens qui m’ont tendu la main, peut-être à un moment où je ne pouvais pas, où j’étais
                    seul chez moi. Et c’est assez curieux de se dire que les hasards, les rencontres forgent une
                    destinée... Parce que quand on a le goût de la chose, quand on a le goût de la chose bien faite, le
                    beau geste, parfois on ne trouve pas l’interlocuteur en face je dirais, le miroir qui vous aide à
                    avancer. Alors ça n’est pas mon cas, comme je disais là, puisque moi au contraire, j’ai pu ; et je
                    dis merci à la vie, je lui dis merci, je chante la vie, je danse la vie... je ne suis qu’amour ! Et
                    finalement, quand des gens me disent « Mais comment fais-tu pour avoir cette humanité ? », je leur
                    réponds très simplement que c’est ce goût de l’amour, ce goût donc qui m’a poussé aujourd’hui à
                    entreprendre une construction mécanique... mais demain qui sait ? Peut-être simplement à me mettre
                    au service de la communauté, à faire le don, le don de soi.</p>
            </div>
        </div>
    </div>
</div>