/// <reference types="@angular/localize" />

import { LOCALE_ID, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { CookieService } from 'ngx-cookie-service';

// https://tinesoft.github.io/ngx-cookieconsent/home
const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": "retro-motors.fr"
  },
  "position": "bottom-right",
  "theme": "edgeless",
  "palette": {
    "popup": {
      "background": "rgba(51,19,61,0.7)",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "rgba(205,64,172,0.7)",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-out",
  "content": {
    "message": "Ce site web utilise des cookies pour vous assurer une meilleure expérience de navigation.",
    "dismiss": "OK, j'ai compris !",
    "deny": "Refuser",
    "link": "",
    "href": "",
    "policy": "Cookies",
    "header": "Cookies sur le site!",
    "allow": "Autoriser les cookies"
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(CookieService, BrowserModule, AppRoutingModule, NgcCookieConsentModule.forRoot(cookieConfig), ReactiveFormsModule, ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideAnimations()
  ]
})
  .catch(err => console.error(err));
