<div class="bloc">
    <carousel [noPause]="true" [interval]=3000 [isAnimated]="true" [showIndicators]="false">
        @for (imageCarousel of imagesCarousel; track $index) {
            <slide>
                <img src="assets/vitres-teintees/carousel/{{imageCarousel}}" alt="Pose d'une vitre teintée"
                    class="mainFrame" height="auto" width="100%">
                <div class="carousel-caption">
                    <p class="title" style="text-shadow: 2px 2px 4px #000000">Optez pour des vitres teintées haut de gamme !
                    </p>
                </div>
            </slide>
        }
    </carousel>
</div>
<div class="col m-5 px-md-5">
    <h3 class="text-center">Pourquoi opter pour des vitres teintées ?</h3>
    <ul class="list-group list-group-flush text-center my-4 col-lg-8 mx-auto">
        <li class="list-group-item">Esthétique unique</li>
        <li class="list-group-item">Protection UV</li>
        <li class="list-group-item">Confidentialité assurée</li>
        <li class="list-group-item">Conduite confortable</li>
    </ul>
    <div class="row my-4">
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .15;">
                <div class="card-body text-center">
                    15% d'opacité
                </div>
            </div>
        </div>
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .5;">
                <div class="card-body text-center">
                    50% d'opacité
                </div>
            </div>
        </div>
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .65;">
                <div class="card-body text-center" style="color: white;">
                    65% d'opacité
                </div>
            </div>
        </div>
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .8;">
                <div class="card-body text-center" style="color: white;">
                    80% d'opacité
                </div>
            </div>
        </div>
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .85;">
                <div class="card-body text-center" style="color: white;">
                    85% d'opacité
                </div>
            </div>
        </div>
        <div class="col-lg-2 my-auto">
            <div class="card bg-black my-2" style="--bs-bg-opacity: .95;">
                <div class="card-body text-center" style="color: white;">
                    95% d'opacité
                </div>
            </div>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h2 class="card-title">Citadine / Compacte</h2>
                </div>
                <div class="card-body">
                    <img src="assets/vitres-teintees/citadine.webp" alt="Voiture citadine" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>139€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h2 class="card-title">Berline / Coupé</h2>
                </div>
                <div class="card-body">
                    <img src="assets/vitres-teintees/berline.webp" alt="Voiture berline" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>159€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h2 class="card-title">SUV / Break</h2>
                </div>
                <div class="card-body">
                    <img src="assets/vitres-teintees/suv.webp" alt="Voiture SUV" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>179€</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <a style="text-decoration: none" id='Setmore_button_iframe' class="col m-md-2"
            href='https://booking.setmore.com/scheduleappointment/b2cfe000-c9db-4b81-ad05-334aa223be33'>
            <button class="reservation w-100">Réserver maintenant !</button>
        </a>
    </div>
    <div class="my-3">
        <p class="row text-center">
            Nos experts en pose de vitres teintées sont là pour vous offrir un service professionel et de qualité. Nous
            utilisons des films teintés de haute qualité, garantissant une installation sans bulles, ni plis.
            Transformez votre conduite avec style grâce à notre service professionel de vitres teintées.
        </p>
    </div>
</div>