import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-qui-sommes-nous',
    templateUrl: './qui-sommes-nous.component.html',
    styleUrl: './qui-sommes-nous.component.css'
})
export class QuiSommesNousComponent implements OnInit {
  constructor(private readonly meta: Meta, private readonly titleService: Title, @Inject(DOCUMENT) private readonly document: Document) {
    this.titleService.setTitle("Qui sommes-nous ?");
    this.meta.updateTag({ name: 'description', content: 'Retro Motors : les contributeurs' })
  }
  ngOnInit(): void {
    const link = this.document.querySelector(`link[rel='canonical']`);
    if (link) {
      link.setAttribute('href', 'https://retro-motors.fr/qui-sommes-nous');
    }
  }

  imageBoss = 'assets/qui-sommes-nous/boss.webp';
  imageDevsecops = 'assets/qui-sommes-nous/devsecops.webp';
  imagePhotographe = 'assets/qui-sommes-nous/photographe.webp';
}
