<div class="bloc">
    <carousel [noPause]="true" [interval]=3000 [isAnimated]="true" [showIndicators]="false">
        <slide>
            <img [src]="imagePrincipale" alt="Accueil : nettoyage, covering et vitres teintées"
                class="mainFrame" height="auto" width="100%">
            <div class="carousel-caption">
                <p class="title" style="text-shadow: 2px 2px 4px #000000">Ton nouveau professionnel automobile à Orléans !</p>
            </div>
        </slide>
    </carousel>
</div>
<div class="col m-5 px-md-5">
    <div class="row py-4 m-md-4">
        <div class="col-md-3 m-auto">
            <img [src]="imageWash" alt="Nettoyage automobile" width="300" height="300" fetchpriority="high"
                class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
        </div>
        <div class="col-md-9 my-auto">
            <p class="row m-2 py-3 description">Votre voiture mérite le meilleur soin ! C'est pourquoi nous sommes déterminés à
                offrir à votre
                véhicule un travail d'exception. Pour ce faire nous traitons chaque voiture avec le plus grand respect,
                en
                utilisant des techniques et des produits de nettoyage de pointe pour éliminer la saleté, les tâches et
                les
                imperfections.</p>
            <button class="row mx-auto m-md-2 px-3" routerLink="wash">Voir nos tarifs</button>
        </div>
    </div>
    <div class="row py-4 m-md-4">
        <div class="col-md-3 order-md-2 m-auto">
            <img [src]="imageCover" alt="Covering automobile" width="300" height="300"
                class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
        </div>
        <div class="col-md-9 order-md-1 my-auto">
            <p class="row m-2 py-3 description">Protection, style et originalité, tout dans un seul endroit. Retro Cover ! Avez-vous
                déjà rêvé de donner une nouvelle vie à votre véhicule, de le rendre unique et vraiment à votre image ?
                Le Covering Auto est la réponse à vos envies de personnalisation automobile ! Imaginez une infinité
                de possibilités de design, des couleurs vibrantes aux motifs audacieux, tout cela sans altérer la
                peinture d'origine de votre voiture !</p>
            <button class="row mx-auto m-md-2 px-3" routerLink="covering">Personnaliser votre véhicule</button>
        </div>
    </div>
    <div class="row py-4 m-md-4">
        <div class="col-md-3 m-auto">
            <img [src]="imageVitrage" alt="Vitres teintées" width="300" height="300"
                class="rounded-circle img-fluid ball d-block shadow-lg mx-auto m-md-2">
        </div>
        <div class="col-md-9 my-auto">
            <p class="row m-2 py-3 description">La vie privée à chaque virage, le style à chaque trajet ! Optez pour des vitres
                teintées haut de gamme ! Vous cherchez à vous protégez contre les UV, apporter une touche de modernité
                ou tout simplement vous sentir à l'abri des regards indiscrets ? Faites appels à nos services ! Notre
                équipe de professionnels expérimentés assure une installation rapide dans le respect des normes légales
                en matières de teintes.</p>
            <button class="row mx-auto m-md-2 px-3" routerLink="vitres-teintees">Découvrir notre gamme</button>
        </div>
    </div>
</div>