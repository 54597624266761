import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CarouselComponent, SlideComponent } from 'ngx-bootstrap/carousel';

@Component({
    selector: 'app-covering',
    templateUrl: './covering.component.html',
    styleUrl: './covering.component.css',
    imports: [CarouselComponent, SlideComponent]
})
export class CoveringComponent implements OnInit {
  constructor(private readonly titleService: Title, private readonly meta: Meta, @Inject(DOCUMENT) private readonly document: Document) {
    this.titleService.setTitle("Covering automobile");
    this.meta.updateTag({ name: 'description', content: 'Ton nouveau spécialiste du covering à Orléans ! Personnalisez votre véhicule dans notre centre. Le covering permet de recouvrir partiellement ou intégralement la carrosserie de votre véhicule. Une alternative à la peinture !' })
  }
  ngOnInit(): void {
    const link = this.document.querySelector(`link[rel='canonical']`);
    if (link) {
      link.setAttribute('href', 'https://retro-motors.fr/covering');
    }
  }

  imagesCarousel: string[] = [
    "covering_1.webp",
    "covering_2.webp",
    "covering_3.webp"
  ]
}
