<div class="bloc">
    <carousel [noPause]="true" [interval]=3000 [isAnimated]="true" [showIndicators]="false">
        @for (imageCarousel of imagesCarousel; track $index) {
            <slide>
                <img src="assets/covering/carousel/{{imageCarousel}}" alt="Pose d'un film de covering"
                    class="mainFrame" height="auto" width="100%">
                <div class="carousel-caption">
                    <p class="title" style="text-shadow: 2px 2px 4px #000000">Une nouvelle façon de personnaliser votre auto !</p>
                </div>
            </slide>
        }
    </carousel>
</div>
<div class="col m-5 px-md-5">
    <p class="row description">
        Ton nouveau spécialiste du covering à Orléans ! Personnalisez votre véhicule dans notre centre. Le covering
        permet de recouvrir partiellement ou intégralement la carrosserie de votre véhicule. Une alternative à la
        peinture ! Celui-ci est totalement réversible et se retire facilement sans abîmer la peinture d'origine de votre
        véhicule.
    </p>
    <div class="row my-4">
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h4 class="card-title">Citadine / Cabriolet / Compacte</h4>
                </div>
                <div class="card-body">
                    <img src="assets/covering/citadine.webp" alt="Voiture citadine" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>1590€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h4 class="card-title">Berline / Coupé</h4>
                </div>
                <div class="card-body">
                    <img src="assets/covering/berline.webp" alt="Voiture berline" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>1790€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h4 class="card-title">SUV / Break</h4>
                </div>
                <div class="card-body">
                    <img src="assets/covering/suv.webp" alt="Voiture SUV" class="img-fluid">
                </div>
                <div class="card-footer">
                    <h1>1990€</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <button class="col m-md-2 reservation w-100 mx-auto" data-openwidget-action="maximize"
        data-openwidget-feature="form-contact">Contactez-nous !</button>
    </div>
    <div class="row my-4">
        <div class="col-lg-6">
            <h5 class="text-center">Comment ça marche ?</h5>
            <ul class="list-group list-group-flush text-center my-4 mx-auto">
                <li class="list-group-item">Décontamination</li>
                <li class="list-group-item">Démontage</li>
                <li class="list-group-item">Pose</li>
                <li class="list-group-item">Remontage</li>
                <li class="list-group-item">Contrôle</li>
                <li class="list-group-item">Shooting photo (en option)</li>
            </ul>
        </div>
        <div class="col-lg-6 my-3 my-auto">
            <img src="assets/covering/emplacements.webp" alt="Emplacements d'un covering : carrosserie, phares avant, phares arrière" class="img-fluid d-block mx-auto">
        </div>
    </div>
    <p class="row description">
        De nombreuses textures et un large choix de coloris ! Mat, brillant, satiné, métallisé, chrome, carbone ...
        Le tout avec des marques reconnues comme 3M, Teckwrap, Avery Dennison ...
    </p>
</div>