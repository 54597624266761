<div class="bloc">
    <carousel [noPause]="true" [interval]=3000 [isAnimated]="true" [showIndicators]="false">
        @for (imageCarousel of imagesCarousel; track $index) {
            <slide>
                <img src="assets/wash/carousel/{{imageCarousel}}" alt="Nettoyage d'un véhicule" class="mainFrame"
                    height="auto" width="100%">
                <div class="carousel-caption">
                    <p class="title" style="text-shadow: 2px 2px 4px #000000">Découvrez le futur de la propreté automobile !
                    </p>
                </div>
            </slide>
        }
    </carousel>
</div>
<div class="col m-5 px-md-5">
    <p class="row description">
        Découvrez nos tarifs de nettoyage automobile pour des services de qualité exceptionnelle. Retro Wash vous offre
        une gamme de forfaits de nettoyage automobile abordable, conçus pour répondre à vos besoins spécifiques tout en
        respectant votre budget.
    </p>
    <div class="row my-4">
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h1 class="card-title">Intérieur</h1>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>Nettoyage vitres intérieures</li>
                        <li>Aspiration en profondeur</li>
                        <li>Nettoyage passage de porte</li>
                        <li>Nettoyage plastiques intérieur</li>
                        <li>Nettoyage sièges / tapis / moquettes</li>
                        <li>Parfums</li>
                    </ul>
                </div>
                <div class="card-footer">
                    <h1>50€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h1 class="card-title">Complet</h1>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>Démoustiquage</li>
                        <li>Lavage carroserie</li>
                        <li>Nettoyage jantes / pneus</li>
                        <li>Nettoyage vitres intérieures / extérieures</li>
                        <li>Nettoyage passage de porte</li>
                        <li>Nettoyage plastiques intérieur</li>
                        <li>Nettoyage sièges / tapis / moquettes</li>
                        <li>Parfums</li>
                    </ul>
                </div>
                <div class="card-footer">
                    <h1>80€</h1>
                </div>
            </div>
        </div>
        <div class="col-md-4 my-auto">
            <div class="card text-center my-2">
                <div class="card-header">
                    <h1 class="card-title">Extérieur</h1>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>Démoustiquage</li>
                        <li>Lavage carroserie</li>
                        <li>Nettoyage jantes / pneus</li>
                        <li>Nettoyage vitres extérieures</li>
                        <li>Protection et déperlant carroserie</li>
                        <li>Parfums</li>
                    </ul>
                </div>
                <div class="card-footer">
                    <h1>40€</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <a style="text-decoration: none" id='Setmore_button_iframe' class="col m-md-2"
            href='https://booking.setmore.com/scheduleappointment/b2cfe000-c9db-4b81-ad05-334aa223be33'>
            <button class="reservation w-100">Réserver maintenant !</button>
        </a>
    </div>
    <div class="my-3">
        <p class="row description">
            * En fonction de l'état de votre véhicule, un supplément pourra vous être facturé. De plus, pour tout
            véhicule
            de plus de 5 places ou utilitaire merci de nous contacter.
        </p>
        <p class="row description">
            Pour obtenir un devis personnalisé ou pour discuter de nos tarifs en détail, n'hésitez pas à nous contacter.
            Nous sommes là pour répondre à toutes vos questions et pour vous aider à choisir le forfait de nettoyage
            automobile qui convient le mieux à vos besoins.
        </p>
    </div>
</div>