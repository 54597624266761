<nav class="navbar fixed-top rounded-bottom-4 shadow-lg">
    <div class="container-fluid">
        <span class="mx-auto mx-lg-2"><a routerLink="" class="navbar-brand title mx-auto">Retro Motors</a></span>
        <div class="d-flex mx-auto me-lg-2">
            <a routerLink="wash"><img width="65" height="65" src="assets/header/logo_wash.webp" alt="Bouton vers la page de nettoyage" class="logo mx-3 m-xl-0"><button class="m-2 item d-none d-xl-inline-block">Retro Wash</button></a>
            <a routerLink="covering"><img width="65" height="65" src="assets/header/logo_cover.webp" alt="Bouton vers la page de covering" class="logo mx-3 m-xl-0"><button class="m-2 item d-none d-xl-inline-block">Retro Cover</button></a>
            <a routerLink="vitres-teintees"><img width="65" height="65" src="assets/header/logo_vitres.webp" alt="Bouton vers la page des vitres teintées" class="logo mx-3 m-xl-0"><button class="m-2 item d-none d-xl-inline-block">Vitres teintées</button></a>
            <button class="mx-3 contact d-none d-md-inline-block" (click)="goToFooter()">Contact</button>
        </div>
    </div>
</nav>