import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
import { CarouselComponent, SlideComponent } from 'ngx-bootstrap/carousel';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css'],
    imports: [CarouselComponent, SlideComponent, RouterLink]
})
export class AccueilComponent implements OnInit {
  constructor(private readonly meta: Meta, private readonly titleService: Title, @Inject(DOCUMENT) private readonly document: Document) {
    this.titleService.setTitle("Retro Motors - Préparateur automobile");
    this.meta.updateTag({ name: 'description', content: 'Retro Motors, nettoyage automobile, covering et vitres teintées. Ton nouveau préparateur automobile à Chécy proche d\'Orléans !' })
  }
  ngOnInit(): void {
    const link = this.document.querySelector(`link[rel='canonical']`);
    if (link) {
      link.setAttribute('href', 'https://retro-motors.fr');
    }
  }

  imagePrincipale = 'assets/accueil/accueil.webp';
  title = 'Ton nouveau professionnel automobile à connaître sur Orléans !'
  imageWash = 'assets/accueil/wash.webp';
  imageCover = 'assets/accueil/cover.webp';
  imageVitrage = 'assets/accueil/vitrage.webp';

}
