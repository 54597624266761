import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccueilComponent } from "./accueil/accueil.component";
import { WashComponent } from "./wash/wash.component";
import { VitresTeinteesComponent } from "./vitres-teintees/vitres-teintees.component";
import { QuiSommesNousComponent } from "./qui-sommes-nous/qui-sommes-nous.component";
import { CoveringComponent } from "./covering/covering.component";

const routes: Routes = [
    { path: "", component: AccueilComponent },
    { path: "wash", component: WashComponent },
    { path: "vitres-teintees", component: VitresTeinteesComponent },
    { path: "covering", component: CoveringComponent },
    { path: "qui-sommes-nous", component: QuiSommesNousComponent },
    { path: "**", redirectTo: '', component: AccueilComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }