import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { CarouselComponent, SlideComponent } from 'ngx-bootstrap/carousel';

@Component({
    selector: 'app-wash',
    templateUrl: './wash.component.html',
    styleUrls: ['./wash.component.css'],
    imports: [CarouselComponent, SlideComponent]
})
export class WashComponent implements OnInit {
  constructor(private readonly titleService: Title, private readonly meta: Meta, @Inject(DOCUMENT) private readonly document: Document) {
    this.titleService.setTitle("Nettoyage automobile");
    this.meta.updateTag({ name: 'description', content: 'Retro Wash vous offre une gamme de forfaits de nettoyage automobile abordable, conçus pour répondre à vos besoins spécifiques tout en respectant votre budget.' })
  }
  ngOnInit(): void {
    const link = this.document.querySelector(`link[rel='canonical']`);
    if (link) {
      link.setAttribute('href', 'https://retro-motors.fr/wash');
    }
  }

  imagesCarousel: string[] = [
    "wash_1.webp",
    "wash_4.webp",
    "wash_2.webp",
    "wash_7.webp",
    "wash_8.webp",
    "wash_3.webp",
    "wash_5.webp",
    "wash_6.webp",
  ]
}
