import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { CarouselComponent, SlideComponent } from 'ngx-bootstrap/carousel';

@Component({
    selector: 'app-vitres-teintees',
    templateUrl: './vitres-teintees.component.html',
    styleUrl: './vitres-teintees.component.css',
    imports: [CarouselComponent, SlideComponent]
})
export class VitresTeinteesComponent implements OnInit {
  constructor(private readonly titleService: Title, private readonly meta: Meta, @Inject(DOCUMENT) private readonly document: Document) {
    this.titleService.setTitle("Pose de vitres teintées");
    this.meta.updateTag({ name: 'description', content: 'Nos experts en pose de vitres teintées sont là pour vous offrir un service professionel et de qualité. Nous utilisons des films teintés de haute qualité, garantissant une installation sans bulles, ni plis. Transformez votre conduite avec style grâce à notre service professionel de vitres teintées.' })
  }
  ngOnInit(): void {
    const link = this.document.querySelector(`link[rel='canonical']`);
    if (link) {
      link.setAttribute('href', 'https://retro-motors.fr/vitres-teintees');
    }
  }

  imagePrincipale = 'assets/vitres-teintees/main.webp';

  imagesCarousel: string[] = [
    "vitres-teintees_1.webp",
    "vitres-teintees_2.webp",
    "vitres-teintees_3.webp",
    "vitres-teintees_4.webp",
    "vitres-teintees_5.webp"
  ]
}
